$(document).ready(function () {

    //slider homepage
    $('.mainSlider').owlCarousel({
        items: 1,
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        smartSpeed: 1800,
        nav: false,
        dots: true,
        //navText: ["<img src='img/left_icon_slider.png'>","<img src='img/right_icon_slider.png'>"]  //wylaczenie prawo/lewo
    })

    $('.moreServicesBox').owlCarousel({
        items: 1,
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        smartSpeed: 1800,
        nav: false,
        dots: true,
        //navText: ["<img src='img/left_icon_slider.png'>","<img src='img/right_icon_slider.png'>"]  //wylaczenie prawo/lewo
    })



    /*function initMap() {

        var uluru = {
            lat: 50.0586539,
            lng: 19.9427508
        };

        var map = new google.maps.Map(
            document.getElementById('map'), {
                zoom: 15,
                center: uluru,
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false

            });

        var image = 'https://cameraapartments.pl/wp-content/themes/camera/img/marker_icon.png';

        var marker = new google.maps.Marker({
            position: uluru,
            map: map,
            animation: google.maps.Animation.DROP,
            icon: image,
            title: "Camera apartaments"
        });

        map.addListener('mouseover', toggleBounce);

        var contentString = '<div id="content">' +
            '<div id="siteNotice">' +
            '</div>' +
            '<h3 id="firstHeading" class="firstHeading text-center">Costa Del Kryspi</h3>' +
            '<div id="bodyContent" class="text-center pt-5">' +
            '<p>30-001 Kryspinów</p>'
        '</div>' +
            '</div>';

        var infowindow = new google.maps.InfoWindow({
            content: contentString
        });

        marker.addListener('mouseover', function () {
            infowindow.open(map, marker);
        });

        function toggleBounce() {
            if (marker.getAnimation() !== null) {
                marker.setAnimation(null);
            } else {
                marker.setAnimation(google.maps.Animation.BOUNCE);
            }
        }

    }*/


    // When the user scrolls the page, execute myFunction 
    window.onscroll = function() {myFunction()};

    // Get the header
    var header = document.querySelector("nav");

    // Get the offset position of the navbar
    //var sticky = header.offsetTop;

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function myFunction() {
        if(window.innerWidth > 768){
            console.log("Większe od 768")
            if (window.pageYOffset > 70) {
                header.classList.add("activeTop");
                console.log("add sticky")
            } else {
                header.classList.remove("activeTop");
                console.log("remove sticky")
            }
        }else{
            console.log("mniejsze od 768")
            if (window.pageYOffset > 1) {
                header.classList.add("activeTop");
                console.log("add sticky")
            } else {
                header.classList.remove("activeTop");
                console.log("remove sticky")
            }
        }
    }

    $('#toContact').click(function(){
        $('html, body').animate({
            scrollTop: $("section.contact").offset().top - 20
        }, 1000);
    })

    $('#toMap').click(function(){
        $('html, body').animate({
            scrollTop: $("section.map").offset().top - 20
        }, 700);
    })

    $('.navbar-brand').click(function(){
        $('html, body').animate({
            scrollTop: 0
        }, 700);
    })


    $('#ourHouseLink').click(function(){
        $('html, body').animate({
            scrollTop: $("section.ourHouse").offset().top - 20
        }, 700);
    })

    $('#moreServicesBoxLink').click(function(){
        $('html, body').animate({
            scrollTop: $("section.moreServicesBox").offset().top - 20
        }, 700);
    })

    $('#areaLink').click(function(){
        $('html, body').animate({
            scrollTop: $("section.area").offset().top - 20
        }, 700);
    })

    $('#mapLink').click(function(){
        $('html, body').animate({
            scrollTop: $("section.map").offset().top - 20
        }, 700);
    })

    $('#contactLink').click(function(){
        $('html, body').animate({
            scrollTop: $("section.contact").offset().top - 20
        }, 700);
    })
    
    //scrollspy
    $('nav').stickynav();

    $('#myModal').on('shown.bs.modal', function () {
        $('#myInput').trigger('focus')
    })

});

